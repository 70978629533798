.navbar-green{
    --bs-navbar-color: rgb(255, 255, 255);
    --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
    --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: #137E31;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.bg-green{
    background-color: #137E31;
}

.dropdown-menu {
    --bs-dropdown-color: #FFFFFF;
    --bs-dropdown-bg: #137E31;
    --bs-dropdown-border-radius: 0.2rem;
    --bs-dropdown-inner-border-radius: calc(0.2rem - 1px);
    --bs-dropdown-link-color: #FFFFFF;
    --bs-dropdown-link-hover-color: #1e2125;
    --bs-dropdown-link-hover-bg: #e9ecef;
    --bs-dropdown-link-active-color: #137E31;
    --bs-dropdown-link-active-bg: #adb5bd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #6c757d;
  }