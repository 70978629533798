.indicator-div{
  height: 30px;
  margin-top: -29px;
  position: fixed;
}

.btn-renderNode{
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  color:#fff;
}

.btn-renderNode > div {
  position: relative;
  top: -50%;
  left: -50%;
}

.indicator-h2{
  font-size: 12px;
  line-height: 12px;
  color:#fff;
}

.component-selected {
  position: relative;
  border: 1px;
  border-style: dashed;
  border-color: #0d6efd;
}

.flex-1{
  flex: 1 1 0%;
}