.textos{
  height: fit-content;
}

.selecciona-imagen{
  cursor:pointer;
}

.seleccionada-imagen{
  border-width: 2px;
  border-color: #FFF;
  border-style: solid;
}
